html {
    /*overflow: hidden;*/
    margin: 0;
    /*height: 100%;*/
}

html,
body {
    padding: 0;
}

body {
    background-color: #0d2a43 !important;
}

/*body > div:first-child,
div#__next,
div#__next > div {
    height: 100%;
}*/

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.ms-loyalty-down,
.ms-loyalty-up {
    color: rgb(168, 139, 255);
}

.ms-loyalty-down::after,
.ms-loyalty-up::after {
    color: #1c1c1c;
}

.mouse-over-box-shadow {
    transition-duration: 100ms;
}

.mouse-over-box-shadow:hover {
    box-shadow: 4px 6px 3px -2px rgba(0, 0, 0, 0.2),
    5px 4px 4px 0 rgba(0, 0, 0, 0.14), 7px 2px 8px 0 rgba(0, 0, 0, 0.12);
}

.dot {
    width: 14px;
    height: 14px;
    background: #70cad1;
    border-radius: 7px;
}

.line {
    height: 44px;
    width: 2px;
    background: #70cad1;
    margin-left: 5.3px;
}

.mtg-guru.leaflet-popup .leaflet-popup-content-wrapper {
    border-radius: 4px;
    padding: 0;
}

.leaflet-container {
    font-family: inherit !important;
    color: black;
}

.mtg-guru.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content {
    margin: 0;
}

.mtg-guru.leaflet-popup .leaflet-popup-tip {
    background-color: #0d2a43;
}

.mtg-guru .leaflet-popup-content a {
    color: #1c1c1c;
}

.mtg-guru a.leaflet-popup-close-button {
    color: #a88bffff !important;
    width: 32px !important;
    height: 32px !important;
    font-size: 24px !important;
    line-height: 32px !important;

}

.mtg-guru-svg-icon {
    fill: #0d2a43;
}

.cl-headerTitle,
.cl-formFieldLabel {
    color: #CAFF8A;
}

.cl-internal-1cg8dzt {
    height: 100px;
}

.cl-card > div:last-child {
    /*display: none;*/
}

.cl-card .cl-formButtonPrimary:hover {
    background-color: rgb(117, 97, 178);
}

.cl-card .cl-formFieldInputShowPasswordButton:hover {
    color: rgb(117, 97, 178);
}

.cl-card input:-webkit-autofill,
.cl-card input:-webkit-autofill:focus {
    /*https://stackoverflow.com/questions/61083813/how-to-avoid-internal-autofill-selected-style-to-be-applied*/
    transition: background-color 600000s 0s, color 600000s 0s !important;
}

.cl-card .cl-socialButtonsIconButton {
    background: rgba(0, 108, 103, 0.18);
}


.door-frame {
    height: 495px;
    width: 295px;
    border-radius: 90px 90px 0 0;
    background-color: #8594A5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.door {
    height: 450px;
    width: 250px;
    border-radius: 70px 70px 0 0;
    background-color: #A0AEC0;
}

.eye {
    top: 15px;
    left: 25px;
    height: 5px;
    width: 15px;
    border-radius: 50%;
    background-color: white;
    animation: eye 7s ease-in-out infinite;
    position: absolute;
}

.eye2 {
    left: 65px;
}

.window {
    height: 40px;
    width: 130px;
    background-color: #1C2127;
    border-radius: 3px;
    margin: 80px auto;
    position: relative;
}

.leaf {
    height: 40px;
    width: 130px;
    background-color: #8594A5;
    border-radius: 3px;
    margin: 80px auto;
    animation: leaf 7s infinite;
    transform-origin: right;
}

.handle {
    height: 8px;
    width: 50px;
    border-radius: 4px;
    background-color: #EBF3FC;
    position: absolute;
    margin-top: 250px;
    margin-left: 30px;
}

.rectangle {
    height: 70px;
    width: 25px;
    background-color: #CBD8E6;
    border-radius: 4px;
    position: absolute;
    margin-top: 220px;
    margin-left: 20px;
}

@keyframes leaf {
    0% {
        transform: scaleX(1);
    }
    5% {
        transform: scaleX(0.2);
    }
    70% {
        transform: scaleX(0.2);
    }
    75% {
        transform: scaleX(1);
    }
    100% {
        transform: scaleX(1);
    }
}

@keyframes eye {
    0% {
        opacity: 0;
        transform: translateX(0)
    }
    5% {
        opacity: 0;
    }
    15% {
        opacity: 1;
        transform: translateX(0)
    }
    20% {
        transform: translateX(15px)
    }
    35% {
        transform: translateX(15px)
    }
    40% {
        transform: translateX(-15px)
    }
    60% {
        transform: translateX(-15px)
    }
    65% {
        transform: translateX(0)
    }
}

@keyframes flux {
    0%,
    100% {
        text-shadow: 0 0 5px #00FFC6, 0 0 15px #00FFC6, 0 0 50px #00FFC6, 0 0 50px #00FFC6, 0 0 2px #B9FFE8, 2px 2px 3px #12E29C;
        color: #4BFFEF;
    }
    50% {
        text-shadow: 0 0 3px #00B58D, 0 0 7px #00B58D, 0 0 25px #00B58D, 0 0 25px #00B58D, 0 0 2px #00B58D, 2px 2px 3px #006A60;
        color: #63D3AE;
    }
}

.no-pointer-events {
    pointer-events: none;
}

.upload-root {
    cursor: pointer;
    text-align: center;
    display: flex;
    background-color: #0d2a43;
}

.upload-root:hover p, .upload-root:hover svg {
    opacity: 1;
}

.upload-root p, .upload-root svg {
    opacity: 0.4;
}

.upload-root:hover img {
    opacity: 0.3;
}

.hidden {
    display: none;
}

.icon-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: absolute;
}

.opacity-1 {
    opacity: 1 !important;
}

#nprogress .bar {
    z-index: 99999 !important;
}

.ms-ci:before {
    color: #0d2a43!important;
    position: relative;
    top: 1px;
    left: -1px;
    font-size: 1.09em;
}